/* scroll */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--tmc);
    border-radius: 5px;
}
a{
    text-decoration: none;
}
p, h1, h2, h3, h4, h5, h6{
    margin: 0;
}
body{
    margin: 0;
}
.body{
    width: 80%;
    background-color: var(--tmc_bbg);
    transition: var(--fast_t);
}
.body-t{
    width: 100%;
}
.body-Con-area{
    padding: 20px 40px;
    position: relative;
}

.relative{
    position: relative;
}

.w-100{
    width: 100%;
}
.h-100{
    width: 100%;
}
/* mouse */
.cp{
    cursor: pointer;
}
/* mouse ends */

/* margin */
.lm-3{
    margin-left: var(--lm_3);
}
.tm-3{
    margin-top: var(--tm_3);
}
/* margin ends */

/* padding  */
.allp-2{
    padding: var(--allp_2);
}
/* padding ends */

/* ess */
.hide{
    display: none;
}
.flex{
    display: flex;
}
.flex-align{
    display: flex;
    align-items: center;
}
.all-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.space-between{
    justify-content: space-between;
}
.space-around{
    justify-content: space-around;
}
.direction-right{
    flex-direction: row-reverse;
}
.center{
    text-align: center;
}
.vw{
    width: var(--vw);
}
.vh{
    height: var(--vh);
}
.mr-center{
    margin: 0 auto;
}
/* ess ends */

/* login */
.login-card{
    width: 450px;
    padding: 40px 30px;
    border-radius: 15px;
    box-shadow: var(--shadow_4)
}
.login-icon{
    width: 66px;
    height: 66px;
    background-color: #fff;
    filter: var(--shadow_1);
    border-radius: 50%;
    margin-bottom: 19px;
}
.l-icon{
    width: 26px;
    height: 26px;
    color: var(--tmc);
}
.login-head{
    color: var(--h_text);
    font-family: var(--font);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 19px;
}
.login-para{
    color: var(--g_text);
    font-family: Pitagon Sans Text;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 162.5%; /* 19.5px */
}
.login-textbox{
    margin: 25px auto;
}
.rmp-box{
    margin: 0 10px 0 0 ;
}
.rmp{
    color: #666;
    font-family: var(--font);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 162.5%; /* 19.5px */
}
.rmp-link{
    color: #007BEC;
}
.login-btn{
    margin-top: 40px;
}
/* error */
.mr-err-t{
    margin-top: 18%;
}
/* error end */

/* login end*/