::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--tmc);
  border-radius: 5px;
}

a {
  text-decoration: none;
}

p, h1, h2, h3, h4, h5, h6, body {
  margin: 0;
}

.body {
  background-color: var(--tmc_bbg);
  transition: var(--fast_t);
  width: 80%;
}

.body-t {
  width: 100%;
}

.body-Con-area {
  padding: 20px 40px;
  position: relative;
}

.relative {
  position: relative;
}

.w-100, .h-100 {
  width: 100%;
}

.cp {
  cursor: pointer;
}

.lm-3 {
  margin-left: var(--lm_3);
}

.tm-3 {
  margin-top: var(--tm_3);
}

.allp-2 {
  padding: var(--allp_2);
}

.hide {
  display: none;
}

.flex {
  display: flex;
}

.flex-align {
  align-items: center;
  display: flex;
}

.all-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.direction-right {
  flex-direction: row-reverse;
}

.center {
  text-align: center;
}

.vw {
  width: var(--vw);
}

.vh {
  height: var(--vh);
}

.mr-center {
  margin: 0 auto;
}

.login-card {
  box-shadow: var(--shadow_4);
  border-radius: 15px;
  width: 450px;
  padding: 40px 30px;
}

.login-icon {
  filter: var(--shadow_1);
  background-color: #fff;
  border-radius: 50%;
  width: 66px;
  height: 66px;
  margin-bottom: 19px;
}

.l-icon {
  color: var(--tmc);
  width: 26px;
  height: 26px;
}

.login-head {
  color: var(--h_text);
  font-family: var(--font);
  margin-bottom: 19px;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.login-para {
  color: var(--g_text);
  font-family: Pitagon Sans Text;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
}

.login-textbox {
  margin: 25px auto;
}

.rmp-box {
  margin: 0 10px 0 0;
}

.rmp {
  color: #666;
  font-family: var(--font);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 162.5%;
}

.rmp-link {
  color: #007bec;
}

.login-btn {
  margin-top: 40px;
}

.mr-err-t {
  margin-top: 18%;
}

/*# sourceMappingURL=index.dcde7509.css.map */
